import './bootstrap';
import './scss/app.scss';
 
const $ = require('jquery');
 
require('bootstrap');


require('webpack-jquery-ui');
require('webpack-jquery-ui/css');  

 
import 'bootstrap/dist/js/bootstrap.js';
 
  
 
require('datatables.net');
require('datatables.net-bs5');

import Routing from 'fos-router';

 
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

/*
import Swipe from 'swipejs';
require('swipejs/style.css');  
*/
 

/*
require('jquery-touchswipe/jquery.touchSwipe.js');
 */

//import Swiper from 'swiper';
//require('swiper');
//require('swiper/swiper-bundle.js');
//import Swiper from 'swiper/swiper-bundle.js'
//import 'swiper/swiper-bundle.js'
 
//var Swiper = require('swiper');


import html2canvas from 'html2canvas';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


 const swiper = new Swiper('.swiper', {
    modules: [Navigation, Pagination], 
    onSlideChangeEnd: function (swiperObj) 
    {
        $('.swiper-slide').css('scrollTop', '0');
        $('.swiper-wrapper').css('scrollTop', '0');
        $('.swiper').css('scrollTop', '0');
        alert("OK");
    }
    
  });

function resize()
{
    /*
    var height_lecteur =Math.floor(($("#main_content_right").width()/16)*9);
    $("#youtube-player-container").height(height_lecteur);
     * 
     */
    if ($(window ).height()<$(window ).width())
    {
        $("#phone-header").addClass("horizontal-view"); 
    }
    else
    {
        $("#phone-header").removeClass("horizontal-view"); 
    }
    
    var nheight=$( window ).height()-($("#video-player").height()+$("#toolbar-player").height()+$("#radio").height());
    $("#currentplaylist_liste").height(nheight-35);
    
    
//    $(".tile").width($(".row_flux").width()/$nb_vids_flux);
		
}
    
    
 
$( window ).resize(function() {
        resize();
})
;
     
 


  function capture() {
                    html2canvas($('body')[0],{
                        onrendered: function (canvas) {                     
                               var imgString = canvas.toDataURL("image/png");
                               var a = document.createElement('a');
                               a.href = imgString;
                               a.download = "image.png";
                               document.body.appendChild(a);
                               a.click();
                               document.body.removeChild(a);              
                    }
                });
            };
            
 
     
$(document).ready(function() 
{
    resize();
    
    

    $(".btn-group-steam ").on('click', '.btn', function() 
    {
          // capture();
     
        html2canvas($(this)[0]).then(function(canvas) {
            $( "#container-video-playlist" ).append(canvas);
        });
       
    });
    
    
    $(window).bind('load', function() {
        $('img').each(function() {
        
        var image=$(this);
        if( (typeof this.naturalWidth != "undefined" && this.naturalWidth == 0)  ||  this.readyState == 'uninitialized' ) 
        {
       
            if($(this).data('code-user-youtube'))
            {
                $.getJSON( Routing.generate('youtube_user_get_banner',{'codeUser':$(this).data('code-user-youtube')}), function( data ) 
                {
                    image.attr('src', data.url);
                });
            }
            else if($(this).data('album-id'))
            {
                $.getJSON( Routing.generate('indaflux_album_get_cover',{'albumMbid':$(this).data('album-id')}), function( data ) 
                {
                    image.attr('src', data.url);
                });
            }
            else
            {
                image.attr('src', "https://iciouailleurs.org/build/img/notfound/album_not_found.jpg");
            }
            
            
        }
        });
    });
    
     


    $(document).on("scroll", function()
    {

      if ($(document).scrollTop() > 100){
        $("#phone-header").addClass("scroll-down"); 
      }
      else 
      {
        $("#phone-header").removeClass("scroll-down"); 
      }
    });


    swiper.on('slideChange', function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
   });


    $('.swiper-slide.menu').on('click', '.nav-link', function() 
    {
         swiper.slideTo(1);
    });

    $('#main').on('click', '.delete-video', function() 
    {
        var video=$(this).closest(".video-card").parent();
        $.ajax({
          url: $(this).data('delete_path')
        }).done(function() 
        {
            video.remove();
        });
    });

    
    $('#main').on('click', '.music-biblio-icon.artist.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('artist_user_dislike',{'id':$(this).data('id-artist')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.music-biblio-icon.artist.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('artist_user_like',{'id':$(this).data('id-artist')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    
    
    
    $('#main').on('click', '.music-biblio-icon.youtube-user.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_remove_biblio_music',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.music-biblio-icon.youtube-user.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_add_biblio_music',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    












    $('#main').on('click', '.music-biblio-icon.youtube-playlist.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_playlist_remove_biblio_music',{'codePlaylistYoutube':$(this).data('code-playlist-youtube')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.music-biblio-icon.youtube-playlist.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_playlist_add_biblio_music',{'codePlaylistYoutube':$(this).data('code-playlist-youtube')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    











    $('#main').on('click', '.watchlater-icon.youtube-user.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_unwatchlater',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.watchlater-icon.youtube-user.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_watchlater',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    

    
    $('#main').on('click', '.watchlater-icon.youtube-video.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_video_unwatchlater',{'codeYoutube':$(this).data('code-youtube')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.watchlater-icon.youtube-video.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_video_watchlater',{'codeYoutube':$(this).data('code-youtube')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    

    


    $('#main').on('click', '.suscribe-icon.youtube-user.suscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_unsuscribe',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "notsuscribed" );
          icon.removeClass( "suscribed" );
        });
    });
    
    $('#main').on('click', '.suscribe-icon.youtube-user.notsuscribed', function() 
    {
        var icon=$(this);
        $.ajax({
          url: Routing.generate('youtube_user_suscribe',{'codeUser':$(this).data('code-user-youtube')})
        }).done(function() {
          icon.addClass( "suscribed" );
          icon.removeClass( "notsuscribed" );
        });
    });
    
    


    $('#main').on('click', '.video-more-info', function() 
    {
        $("#info-video-detail").load(Routing.generate('info_video_detail',{'codeyoutube':$(this).data('codeyoutube')}));
    });
    
    $('#phone-body #main').on('click', '.video-more-info', function() 
    {
        swiper.slideTo(2);
    });
    
            

    
    
    $('[data-toggle="popover"]').popover();
    
      /*
    var element = document.getElementById('swipe_zone');
    window.mySwipe = new Swipe(element, {
      startSlide: 1,
      draggable: true,
      autoRestart: false,
      continuous: false,
      disableScroll: false,
      stopPropagation: false
    });
*/


});



document.addEventListener('turbo:frame-render', async e => {
  if (['main', '_top'].includes(e.target.id))
    history.pushState(history.state, '', await e.detail.fetchResponse.location)
})
window.addEventListener('popstate', () => Turbo.visit(document.location))

 