import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
    connect() {
        const counterNumberElement = this.element.getElementsByClassName('counter-count')[0];
        this.element.addEventListener('click', () => {
            this.count++;
            counterNumberElement.innerText = this.count;
        });
    }
}