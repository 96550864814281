import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() 
    {
        this.count=666;
//        this.element.textContent = 'Hello Stimulus! Edit me in assets/controllers/hello_controller.js';
    }
    
    add() 
    {
        alert("45435");
        this.count++;
        this.countTarget.innerText = this.count;
    }
    
}

